<!-- 盘存记录 -->

<template>
  <div class="records">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="reocrdsCodeValue" placeholder="请搜索盘存编号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="personValue" placeholder="请搜索操作人" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" plain @click="importShow = true">盘存导入</el-button>
        <el-button class="btn" type="primary" plain @click="handleExport">盘存导出</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="checkNum" label="盘存编号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdUserName" label="操作人"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdTime" label="时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>


    <!-- 导入申请弹窗 -->
    <el-dialog title="导入" :visible.sync="importShow" :before-close="onImportCancel">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" required>
              <el-upload :action="apiBaseUrl" :headers="apiHeaders" :file-list="importFiles" accept=".xlsx,.xls"
                :on-change="beforeUpload" :auto-upload="false" :limit="1" :on-exceed="handleExceed"
                :on-remove="handleRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red;">提示：只能上传"xlsx"、"xls"文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onImportCancel">关 闭</el-button>
        <el-button type="primary" @click="submitFormImport('importRuleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';//导出
export default {
  data() {
    return {
      loading: false,
      dayPass: false,
      reocrdsCodeValue: '', // 请搜索盘存编号
      personValue: '', // 请搜索操作人
      // 表格数据
      tableData: [],
      exportData: [],
      // 导入弹窗数据
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.importInventoryCheck,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importShow: false,
      importFiles: [],  //文件列表
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getInventoryCheckList();
    this.getExportList();
  },

  methods: {
    getInventoryCheckList() {
      this.loading = true;
      this.$axios.get(this.$api.getInventoryCheckList, {
        params: {
          checkNum: this.reocrdsCodeValue || null,  // 请搜索盘存编号
          createdUserName: this.personValue || null,  // 请搜索操作人
          shopId: this.$store.state.userInfo.shopId, //shopId
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {

          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 导入弹窗关闭
    onImportCancel() {
      this.importShow = false;
      this.importFiles = [];
      this.getInventoryCheckList();
    },
    beforeUpload(file) {
      console.log(file);
      const suffixArr = ["xlsx", "xls"];
      const maxSize = 1024 * 1024 * 5;
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"xlsx"、"xls"文件`);
        this.importFiles = [];
        return false;
      }
      if (file.size > maxSize) {
        this.$message.error("附件不能超过5M");
        this.importFiles = [];
        return false;
      }
      this.importFiles.push(file.raw)
    },
    handleExceed() {
      this.$message.warning(`只能上传一个文件！`);
    },
    handleRemove() {
      this.importFiles = [];
    },
    // 导入表单校验
    submitFormImport() {
      if (!this.importFiles || this.importFiles.length == 0) {
        return this.$message.warning('请先上传文件');
      }
      let parmas = new FormData()
      parmas.append('file', this.importFiles[0])
      this.$axios.post(this.$api.importInventoryCheck, parmas).then((res) => {
        if (res.data.code == 100) {
          this.$message.success('导入成功!');
          this.onImportCancel();
        }
      })
    },
    // 查看
    handleCheck(row) {
      this.$router.push({ path: 'detail/recordsView', query: { checkNum: row.checkNum } })
    },
    // 导出数据
    getExportList() {
      this.$axios.get(this.$api.exportInventoryCheck, {
        params: {
          shopId: this.$store.state.userInfo.shopId, //shopId
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.exportData = res.data.result;
        }
      })
    },
    handleExport() {
      //原始数据数组  
      let sheetData = this.exportData.map((item, index) => {
        return [index + 1, item.serialId, item.productName, item.specs, item.categoryName, item.enterBox, item.factoryName, item.brand, item.validityPeriod, item.oldCount, ''];
      });  // 添加表头  
      sheetData.unshift(['序号', '商品编号', '商品名称', '规格', '商品类目', '入箱数', '厂家', '品牌', '保质期(天)', '当前库存', '更新库存',]);
      // 创建sheet对象  
      let sheet = XLSX.utils.aoa_to_sheet(sheetData);
      // 创建一个工作簿  
      let wb = XLSX.utils.book_new();
      // 将sheet对象添加到工作簿  
      XLSX.utils.book_append_sheet(wb, sheet, 'Sheet1');
      // 导出Excel文件  
      try {
        let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        let url = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.download = '盘存记录.xlsx';//Excel命名
        link.click();

      } catch (e) {
        if (typeof console !== 'undefined') console.error(e);
      }
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getInventoryCheckList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryCheckList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryCheckList();
    },
  }

}



</script>

<style scoped lang="scss">
.records {


  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 180px;
      margin-left: 10px;
    }

    .el-button {
      margin-left: 10px;
    }
  }
}
</style>
